//유효성 검사 함수 처리, 반환 boolean 타입

import {
  DEV_POSITIONS,
  ONE_DAY,
  ONE_HOUR_IN_MILLISECOND,
  VALID_BIRTH,
  VALID_EMAIL,
  VALID_ID,
  VALID_LINK,
  VALID_NAME,
  VALID_PASSWORD,
  VALID_PHONE,
} from 'infra/constants'
import { ScoutRequestBase, ScoutProfile } from 'types/common'

export function isNameValid(v: string) {
  return v.length > 0 && VALID_NAME.test(v)
}

export function isEmailValid(v: string) {
  return VALID_EMAIL.test(v)
}

export function isPhoneValid(v: string) {
  return VALID_PHONE.test(v)
}

export function isPasswordValid(v: string) {
  return VALID_PASSWORD.test(v)
}

export function isLinkValid(v: string) {
  return VALID_LINK.test(v)
}

export function isIdValid(v: string) {
  return VALID_ID.test(v)
}

export function isBirthValid(v: string) {
  return VALID_BIRTH.test(v)
}

export const getWrongNames = (message: string, name: string): string[] => {
  const commonTitles = [
    '팀장님',
    '사장님',
    '부장님',
    '과장님',
    '차장님',
    '대표님',
  ]
  // 공백과 '님', '씨'를 포함한 이름 패턴 생성
  const regex = /(\S+)\s*[님씨]/g
  // 메시지에서 '님', '씨'와 함께 사용된 이름을 추출
  const matchedNames = [...message.matchAll(regex)].map((match) => match[1])
  // 추출된 이름이 없으면 false 반환
  if (!matchedNames.length) return []
  // 모든 추출된 이름이 받는 사람의 이름과 일치하는지 확인
  const allNamesMatch = matchedNames.filter(
    (matchedName) =>
      !matchedName.includes(name) &&
      !matchedName.includes('#') &&
      !commonTitles.includes(matchedName),
  )
  return [...new Set(allNamesMatch)]
}

// TODO(gogo): 현재 id 만 받아오는 곳이 많아서 임시 처리, 서버에서 내려줘야 함
export const isDevPosition = (positionTypeId: number) => {
  return DEV_POSITIONS.has(positionTypeId)
}

export const isEndWithConsonant = (word: string) => {
  const finalChrCode = word.charCodeAt(word.length - 1)
  // 0 = 받침 없음, 그 외 = 받침 있음
  const finalConsonantCode = (finalChrCode - 44032) % 28
  return finalConsonantCode !== 0
}

export const isFileMaxSize = (size: number) => {
  const maxSize = 50 * 1024 * 1024
  if (size > maxSize) return false
  return true
}

export const isProfileNotFilled = (
  profile: ScoutRequestBase | ScoutProfile,
) => {
  if (!profile) return true
  const { introduction, expectation, files, links, careers } = profile

  return (
    (introduction && introduction.length < 20) ||
    (expectation.description && expectation.description.length < 20) ||
    (!files.length && !links.length) ||
    (careers.length > 0 && !careers.find((x) => x.description))
  )
}

export const isValid24Hr = (storageKey: string) => {
  const date = localStorage.getItem(storageKey)
  if (!date) return false
  const diff =
    (Date.now() - Date.parse(JSON.parse(date))) / ONE_HOUR_IN_MILLISECOND
  return diff < 24
}

export const isValid60Days = (date: string) => {
  const diff = (Date.now() - Date.parse(date)) / ONE_DAY
  return diff < 60
}
