import React from 'react'
import styled from 'styled-components'
import { BoldText } from 'components/common/text'
import { TooltipWrapper } from 'components/common/tooltip'

const VARIABLE_EXPLANATION =
  '"#{인재이름}"을 입력하면 메시지를 보낼 때 인재의 이름으로 자동 변환돼요'

export const VariableAddingButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <TooltipWrapper
      description={VARIABLE_EXPLANATION}
      tooltipStyle={{
        right: 0,
        top: '-100%',
        transform: 'translateY(calc(-50% - 4px))',
      }}
    >
      <GrayTextButton onClick={onClick} onMouseDown={(e) => e.preventDefault()}>
        + 변수 입력
      </GrayTextButton>
    </TooltipWrapper>
  )
}

const GrayTextButton = styled(BoldText)`
  white-space: nowrap;
  color: ${(p) => p.theme.color.gray2};
  cursor: pointer;
  @media ${(p) => p.theme.deviceSize.desktop} {
    :hover {
      text-decoration: underline;
    }
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-weight: ${(p) => p.theme.fontWeight.medium};
  }
`
