import { DashboardChatStatusId, DashboardChatStatusType } from 'types/common'

export const HOST_BASE_URL = process.env.NEXT_PUBLIC_HOST_BASE_URL
export const SERVER_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL
export const SERVER_WS_BASE_URL = process.env.NEXT_PUBLIC_SERVER_WS_BASE_URL
export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY
export const MIXPANEL_API_KEY = process.env.NEXT_PUBLIC_MIXPANEL_API_KEY
export const BUGSNAG_API_KEY = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
export const GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
export const NAVER_MAP_CLIENT_ID = process.env.NEXT_PUBLIC_NAVER_MAP_CLIENT_ID
export const KAKAO_REST_API_KEY = process.env.NEXT_PUBLIC_KAKAO_REST_API_KEY
export const KAKAO_JS_KEY = process.env.NEXT_PUBLIC_KAKAO_JS_KEY
export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const ALLOW_CRAWL = process.env.NEXT_PUBLIC_ALLOW_CRAWL
export const IS_BROWSER =
  typeof window !== 'undefined' && typeof window.document !== 'undefined'
export const IS_MAIN_PROD = HOST_BASE_URL === 'https://groupby.kr'

export const YOUTUBE_EMBED_URL = 'https://www.youtube.com/embed'

export const MY_PROFILE_SECTION_COUNT = 6
export const REQUIRED_PROFILE_INFO_COUNT = 2
export const OPTIONAL_PROFILE_INFO_COUNT = 4
export const REQUEST_FORM_OPTIONAL_PROFILE_INFO_COUNT = 5

export const STARTUP_POSITION_ITEM_PER_PAGE = 10
export const PROFILE_ITEM_PER_PAGE = 20
export const APPLICANTS_PER_PAGE = 10
export const APPLICANTS_TAB_ID = 1
export const ACCEPTED_TAB_ID = 2
export const RESUME_ACCEPTED_TAB_ID = 4
export const HAS_CONTACT_TAB_IDS = [2, 4]
export const DROPDOWN_DEFAULT_ID = -1
export const JOB_HELPER_STARTUP_ID = 29

export const CHANNEL_IO_PLUGIN_KEY = 'a1ecd49f-b5b4-4b8f-a3fc-ff9a5052c084'
export const HOTJAR_ID = 3143470

export const COOKIE_KEY_USER = 'GB_AUTH'
export const COOKIE_KEY_STARTUP = 'GB_AUTH_SU'
export const COOKIE_KEY_ADMIN = 'GB_AUTH_ADMIN'
export const HAS_VIEWED_ONBOARDING_KEY = 'hasViewedOnboarding'
export const IS_ONE_PROFILE_VIEW_KEY = 'isOneProfileView'
export const RECOMMEND_REQUESTED_AT_KEY = 'recommendRequestedAt'
export const SHOULD_NOT_SHOW_HOOKING_POP_UP_KEY = 'shouldNotShowHookingPopUp'
export const SHOULD_NOT_SHOW_SCOUT_CALCULATOR_KEY =
  'shouldNotShowScoutCalculator'
export const HAS_CLOSED_PROFILE_FILL_REQUEST_KEY = 'hasClosedProfileFillRequest'
export const HAS_CLOSED_USER_CHATTING_GUIDE_KEY =
  'hasClosedChattingGuideForUser'
export const HAS_CLOSED_STARTUP_CHATTING_GUIDE_KEY =
  'hasClosedChattingGuideForStartup'
export const FUNNEL_KEY = 'funnel'
export const PRE_USER_INFO_KEY = 'preUserInfo'

export const CAREER_SECTION_ID = 'careerSectionTitle'
export const INTRODUCTION_SECTION_ID = 'introductionSectionTitle'
export const EXPECTATION_SECTION_ID = 'expectationSectionTitle'

export const VALID_NAME = /^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/ // 한글/영문만
export const VALID_PHONE = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/ // - 있던 없던 상관 없음
export const VALID_PHONE_IN_TEXT =
  // eslint-disable-next-line no-useless-escape
  /\b[\+]?[(]?[0|1]{2,6}[)]?[-\s\.]?[-\s\/\.0-9]{3,15}\b/m
export const VALID_EMAIL_IN_TEXT =
  // eslint-disable-next-line no-useless-escape
  /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/
export const INVALID_PHONE_MSG =
  '010으로 시작하는 10~11자리 휴대폰 번호를 입력해주세요.'
export const VALID_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/
export const VALID_LINK =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/
export const VALID_YOUTUBE_EMBED_LINK =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:\?v=|embed\/|v\/)?)(\S+)?$/
export const VALID_PASSWORD =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()+|=])[A-Za-z\d~!@#$%^&*()+|=]{8,}$/ // NOTE(son) 8자 이상, 하나 이상의 문자, 숫자, 특문
export const VALID_ID = /^[a-z0-9_]+$/ // 영문 소문자, 숫자, 언더바만 가능
export const VALID_BIRTH =
  /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/
export const CHECK_HTML = /<\/?[a-z][\s\S]*>/
export const VALID_NUMBER = /^[0-9]+$/
export const WITH_HTTP_ADDRESS = /^https?:\/\//i

export const LANGUAGE_CARD_DESCRIPTION =
  '구사할 수 있는 언어와 수준을 설명해주세요.'

export const TALENT_NAME_VARIABLE = '#{인재이름}'
export const TEXT_EDITOR_DEFAULT_VALUE = '<p><br></p>'
export const DASHBOARD_DEFAULT_URL = '/dashboard/scouts/search/new-profile'
export const DASHBOARD_SAVE_LIST_URL = '/dashboard/scouts/save-list'
export const DASHBOARD_CHAT_ROOM_PATH = '/dashboard/chat-rooms'
export const TERMS_OF_USE_URL =
  'https://mulberry-capacity-322.notion.site/c518d1a932534e89b05d931ed5065af5?pvs=4'
export const PRIVACY_POLICY_URL =
  'https://mulberry-capacity-322.notion.site/b2a5d9a7096a4057a9edb9a184f0d6c2?pvs=4'
export const GETTING_HIRED_REWARD_URL = 'https://tally.so/r/3jxKk4'

export const KAKAO_REDIRECT_URI = `${SERVER_BASE_URL}/accounts/kakao/callback`
export const FAKE_LOGIN_URL = `${SERVER_BASE_URL}/admin/fake-login`

export const APPLICATIONS_CHAT_PARAMS = {
  applicationStatuses: [2, 3, 4],
  isResumeAcceptExists: true,
} // 유저가 사용하는 applications params 고정값
export const APPLICATIONS_ALL = {
  applicationStatuses: [1, 2, 3, 4, 5, 6, 7],
}
export const SCOUT_SEARCH_SESSION_KEY = 'scoutSearchSession'

export const REAPPLY_ALERT = `이미 지원하여 불합격한 이력이 있어요. 다시 지원하시겠어요?\n(이번에 지원하면, 4개월 동안 해당 공고에 다시 지원할 수 없어요.)`
export const ONE_HOUR_IN_MILLISECOND = 1000 * 60 * 60
export const ONE_DAY = ONE_HOUR_IN_MILLISECOND * 24
export const DEV_POSITIONS = new Set([
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 33, 37, 39,
])
export const MY_PROFILE_INTRODUCTION_TEXT_BOX_PLACEHOLDER = `그룹바이가 추천 드리는 자기소개 항목\n\n- 업무할 때 본인의 강점을 말씀해주세요.\n- 현재 공부하고 있는 것이 있다면 말씀해주세요.\n- 앞으로의 커리어 목표를 말씀해주세요.`
export const MY_PROFILE_EXPECTATION_TEXT_BOX_PLACEHOLDER = `그룹바이가 드리는 Tip\n\n- 자율성을 부여받아 도전 욕구를 자극시킬 수 있는 환경을 기대합니다.\n- 틀에 얽매이지 않는 유연한 사고를 하고 싶어요.\n- 유능한 동료들이 있는 곳에서 함께 성장하고 싶습니다.`
export const MY_PROFILE_JOB_SEARCHING_REASON_TEXT_BOX_PLACEHOLDER = `그룹바이가 드리는 Tip\n\n- 왜 이직을 결심하게 되었는지 말씀해주세요.\n- 이직을 통해 얻고자 하는 결과를 말씀해주세요.`
export const MY_PROFILE_INTRODUCTION_NOTICE = `학력과 직무 경험으로 알 수 없는 잠재력을 소개해 주세요.`
export const MY_PROFILE_EXPECTATION_NOTICE = `내가 일하고 싶은 스타트업의 모습을 상상하면서 자유롭게 말씀해 주세요.`
export const MY_PROFILE_JOB_SEARCHING_NOTICE = `이직하는 이유를 작성하면 성향에 맞는 스타트업이 매칭될 확률이 높아져요.`
export const DASHBOARD_CHAT_STATUS_IDS: DashboardChatStatusId[] = [
  DashboardChatStatusType.INPROGRESS,
  DashboardChatStatusType.CONTACTLATER,
  DashboardChatStatusType.BREAKDOWN,
  DashboardChatStatusType.SUCCEEDED,
]
export const HIRE_PENALTY_NOTICE =
  '주의사항을 확인하고 인지했음에 체크해주세요.'
export const HIRE_APPLICANT_NOTICE =
  '인재가 보게 될 "지원 현황"에서도 최종 합격으로 변경됩니다.\n최종 합격 처리하시겠어요?'
export const HIRE_SCOUT_NOTICE = '최종 합격 처리하시겠어요?'
export const DASHBOARD_LOGIN_ACCOUNT_NOTICE =
  '스타트업 서비스를 이용하려면 구직자 계정(카카오 로그인)과 별개로 가입을 신청해주셔야 합니다.'

export const DASHBOARD_FILTER_JOB_INTEREST_LEVELS = [
  { id: 11, name: '팀장급 희망' },
  { id: 12, name: 'C-Level 희망' },
]
export const CHAT_SPEECH_BALLOON_WIDTH = 244
export const NA_ACCOUNT_ID = 's_511a07378f8b' // 사이트 식별자 (=네이버공통키, na_account_id)
export const HIDE_PENALTY_NOTICE_STORAGE_KEY = 'hidePenaltyNotice'
export const VALID_VARIABLE_NAME = /#\{(.*?)\}/g // 변수이름
