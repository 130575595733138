import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { BaseText, BoldText } from 'components/common/text'
import { EditIcon } from 'components/common/icon'
import { theme } from 'infra/theme'
import { TALENT_NAME_VARIABLE, VALID_VARIABLE_NAME } from 'infra/constants'

export const ProposalMessageViewer: React.FC<{
  name: string
  message: string
  onClick: () => void
  onChange: (v: string) => void
}> = ({ name, message, onClick, onChange }) => {
  const isIncludedName = message.includes(name)
  let html = message.replaceAll(/\n/g, '<br/>')
  let hasWrongVariable = false
  let prevValue

  const matches = [...message.matchAll(VALID_VARIABLE_NAME)]
  for (const matchVariable of matches) {
    if (matchVariable[1] !== '인재이름' && prevValue !== matchVariable[1]) {
      html = html.replaceAll(
        matchVariable[0],
        `<span class="error">${matchVariable[0]}</span>`,
      )
      hasWrongVariable = true
    }
    prevValue = matchVariable[1]
  }

  if (isIncludedName && !hasWrongVariable) {
    html = html.replaceAll(name, `<span>${name}</span>`)
  }

  if (!isIncludedName && !hasWrongVariable) {
    html = html.replaceAll(
      TALENT_NAME_VARIABLE,
      `<span>${TALENT_NAME_VARIABLE}</span>`,
    )
  }

  return (
    <MessageViewerContainer onClick={onClick}>
      <EditIconWrapper>
        <EditIcon color={theme.color.gray1} size={24} />
      </EditIconWrapper>
      <Column style={{ width: '100%' }}>
        <Row style={{ justifyContent: 'center' }}>
          {(hasWrongVariable || isIncludedName) && (
            <Balloon top={-45} isError={hasWrongVariable}>
              <BaseText>
                {hasWrongVariable
                  ? `#{인재이름} 형식 그대로 사용해야 변수가 적용되어요!`
                  : '직접 이름을 쓰지 않아도 자동으로 입력할 수 있어요!'}
              </BaseText>
              <BalloonCTA
                onClick={(e) => {
                  e.stopPropagation()
                  if (hasWrongVariable) {
                    onChange(message.replaceAll(/#\{[^}]+\}/g, '#{인재이름}'))
                  } else {
                    onChange(message.replaceAll(name, '#{인재이름}'))
                  }
                }}
              >
                변수 적용하기
              </BalloonCTA>
            </Balloon>
          )}
        </Row>
        <ViewerTextWrapper onClick={onClick}>
          <TextField dangerouslySetInnerHTML={{ __html: html }} />
        </ViewerTextWrapper>
      </Column>
    </MessageViewerContainer>
  )
}

const ViewerTextWrapper = styled.div`
  cursor: pointer;
`

const TextField = styled.p`
  text-align: left;
  line-height: 1.6;
  .error {
    color: ${(p) => p.theme.color.alert};
    background-color: #ffeaee;
  }
  span {
    display: inline-block;
    padding: 0 4px;
    line-height: 1.4;
    border-radius: 4px;
    color: ${(p) => p.theme.color.secondary};
    background-color: #d8efff;
  }
`

const Balloon = styled(Column)<{ top: number; isError?: boolean }>`
  gap: 2px;
  position: absolute;
  top: ${(p) => p.top}px;
  padding: 6px 12px;
  border-radius: 8px;
  align-items: center;
  background-color: ${(p) => (p.isError ? '#ffeaee' : '#d8efff')};
  span {
    @media ${(p) => p.theme.deviceSize.mobile} {
      font-size: ${(p) => p.theme.fontSize.small};
    }
    color: ${(p) => (p.isError ? p.theme.color.alert : ' #1f8ff0')};
  }
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
  :after {
    border-top: 10px solid ${(p) => (p.isError ? '#ffeaee' : '#d8efff')};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 0px solid transparent;
    content: '';
    position: absolute;
    top: calc(100% - 0px);
    left: 50%;
    transform: translateX(-50%);
  }
  animation: guideBalloonSlideDesktop 0.8s ease-in-out infinite;
  @keyframes guideBalloonSlideDesktop {
    from {
      top: ${(p) => p.top}px;
    }
    50% {
      top: ${(p) => p.top - 5}px;
    }
    to {
      top: ${(p) => p.top}px;
    }
  }
`

const BalloonCTA = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.small};
  }
  text-decoration: underline;
  cursor: pointer;
`

const MessageViewerContainer = styled.div`
  cursor: pointer;
  position: relative;
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.color.line};
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    svg {
      display: none;
    }
    padding: 16px;
    border-radius: 8px;
    :hover {
      background-color: ${(p) => p.theme.color.line};
      svg {
        display: block;
      }
    }
  }
`
const EditIconWrapper = styled.div`
  top: 8px;
  right: 8px;
  padding: 4px;
  position: absolute;
  border-radius: 100px;
  background-color: ${(p) => p.theme.color.background3};
  @media ${(p) => p.theme.deviceSize.mobile} {
    display: none;
  }
`
