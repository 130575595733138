import React, { Suspense, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Modal } from 'components/common/modal'
import { BottomStickyBar } from 'components/common/bottom-sticky-bar'
import { BtnSize, BtnType, Button } from 'components/common/button'
import { Column, Row } from 'components/common/layout'
import { ProposalWriteCondition } from 'components/dashboard/scouts/proposal/proposal-write-condition'
import { Card, ShadowCard } from 'components/common/card'
import {
  useDashboardScoutRequestDetail,
  useDashboardStartupDetail,
  useInitialScoutProposal,
  useStartupUser,
  useTempScoutProposal,
} from 'apis/hooks'
import { ProposalStore } from 'stores/scouts/proposal'
import {
  DashboardInitialScoutProposal,
  DashboardScoutRequestDetail,
  DashboardStartupDetail,
  DashboardTempScoutProposal,
  StartupUser,
} from 'types/common'
import { observer } from 'mobx-react'
import { ProposalPreview } from 'components/scouts/proposal/proposal-preview'
import { requestToast } from 'components/common/toast'
import { mutate, useSWRConfig } from 'swr'
import { analytics } from 'infra/analytics'
import { LockAccountProcedureModal } from 'components/dashboard/lock-account-procedure'
import { CheckBox } from 'components/common/check-box'
import { BaseText, H4Text, SmallText } from 'components/common/text'
import { ProposalWriteMessage } from 'components/dashboard/scouts/proposal/proposal-write-message'
import { useIsDesktopCSR } from 'utils/hooks'
import { ProposalMessageConfirmModal } from 'components/dashboard/scouts/proposal/proposal-message-confirm-modal'
import { ProfileDetail } from 'components/scouts/search/profile-detail'
import { useStores } from 'stores/globals'
import { noticePrivateInfoDetected } from 'utils/handlers'
import { putRequestDashboardScoutRequestProposal } from 'apis/request'
import { getWrongNames } from 'utils/validations'

const IS_NOT_SEE_PROPOSAL_PREVIEW_KEY = 'DoNotSeeProposalPreview'

export const ProposalWriteModal: React.FC<{
  requestId: number
  onClose: () => void
  onPropose?: (result: number) => void
}> = ({ requestId, onClose, onPropose }) => {
  const { data: user } = useStartupUser()
  const { data: startup } = useDashboardStartupDetail()
  const { data: initial } = useInitialScoutProposal()
  const { data: temp } = useTempScoutProposal(requestId)
  const { data: request } = useDashboardScoutRequestDetail(requestId)
  const isDesktop = useIsDesktopCSR()
  if (!initial || !temp || !request || !user || !startup) return null

  return (
    <Modal width={1045} onClose={onClose} disableCloseBtn={isDesktop}>
      <Proposal
        initial={initial}
        temp={temp}
        request={request}
        user={user}
        startup={startup}
        onClose={onClose}
        onPropose={onPropose}
      />
    </Modal>
  )
}

const Proposal: React.FC<{
  initial: DashboardInitialScoutProposal
  temp: DashboardTempScoutProposal
  request: DashboardScoutRequestDetail
  user: StartupUser
  startup: DashboardStartupDetail
  onClose: () => void
  onPropose?: (result: number) => void
}> = observer(
  ({ initial, temp, request, user, startup, onClose, onPropose }) => {
    const { current: proposalStore } = useRef(
      new ProposalStore({ temp, initial, request, user, startup }),
    )
    const { autoProposalMessageStore } = useStores()
    const autoProposalMessage =
      autoProposalMessageStore.autoMessages[proposalStore.request.id]
    const autoProposalAbleNext =
      proposalStore.pageIndex === 0 &&
      autoProposalMessage &&
      autoProposalMessage.isLoading
    const { cache } = useSWRConfig()
    const isDesktop = useIsDesktopCSR()
    const doNotSeeProposalPreview =
      localStorage.getItem(IS_NOT_SEE_PROPOSAL_PREVIEW_KEY) === 'true'
    const autoProposalDisabled =
      (proposalStore.temp.proposeReason.trim() &&
        !proposalStore.isProposeReasonActive) ||
      (proposalStore.temp.appealPoints.trim() &&
        !proposalStore.isAppealPointsActive) ||
      (autoProposalMessage && autoProposalMessage.isLoading)
    const isLastPage =
      proposalStore.pageIndex === 2 ||
      (proposalStore.pageIndex === 1 && doNotSeeProposalPreview)

    useEffect(() => {
      return () =>
        cache.delete(`/dashboard/scouts/requests/${request.id}/proposal`)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const prev = () => proposalStore.setPageIndex(proposalStore.pageIndex - 1)

    const next = () => proposalStore.setPageIndex(proposalStore.pageIndex + 1)

    const propose = async () => {
      if (proposalStore.isLoading) return
      if (!proposalStore.user.isSigned)
        return proposalStore.setIsOpenLockAccount(true)
      try {
        if (autoProposalMessage && autoProposalMessage.isLoading) {
          throw 'AI 메시지가 생성 중입니다.'
        }
        proposalStore.checkValidity()
        if (
          !doNotSeeProposalPreview &&
          !confirm(`${request.name}님에게 메시지를 보낼까요?`)
        )
          return
        proposalStore.setIsLoading(true)
        const result = await requestToast<number>(
          proposalStore.finalize(request.id),
          {
            loading: '전송중',
            success: user.isPublished
              ? '채팅에서 메시지를 확인할 수 있어요.'
              : '상세페이지가 미완료 상태여서 작성 완료되면 자동으로 발송될 수 있게 메시지를 예약해드렸어요.',
          },
          { duration: 4000 },
        )
        proposalStore.setIsLoading(false)
        if (proposalStore.isCheckedDoNotSee)
          localStorage.setItem(IS_NOT_SEE_PROPOSAL_PREVIEW_KEY, 'true')
        onClose()
        onPropose?.(result)
        await mutate(`/dashboard/scouts/proposals/initial`)
        analytics.track('searchProfile_compu_click_completeProposalBtn', {
          compName: user.username,
        })
        analytics.addIdentify('clickProposalCompleteNumber', 1)
      } catch (e) {
        alert(e)
      }
    }

    return (
      <>
        <Section>
          <Suspense>
            {isDesktop && proposalStore.pageIndex < 2 && (
              <LeftContent>
                <ProfileDetail data={request} isNarrow />
              </LeftContent>
            )}
            <RightContent>
              {proposalStore.pageIndex === 0 ? (
                <ProposalWriteMessage proposalStore={proposalStore} />
              ) : proposalStore.pageIndex === 1 ? (
                <ProposalWriteCondition proposalStore={proposalStore} />
              ) : (
                <PreviewContainer>
                  <Header>
                    <MainText>{request.name}님에게 이렇게 보여요</MainText>
                    <SubText>
                      {`흐름이 어색하거나\n불친절한 부분이 있는지 확인해보세요`}
                    </SubText>
                  </Header>
                  <PreviewContent>
                    <ProposalPreview
                      startupId={user.startupId}
                      requestId={request.id}
                      proposal={proposalStore.temp}
                    />
                  </PreviewContent>
                </PreviewContainer>
              )}
            </RightContent>
          </Suspense>
        </Section>
        <BottomBar>
          {proposalStore.pageIndex === 2 && (
            <CheckBox
              checked={proposalStore.isCheckedDoNotSee}
              onClick={() => {
                proposalStore.setIsCheckedDoNotSee(
                  !proposalStore.isCheckedDoNotSee,
                )
              }}
            >
              다시 보지 않기
            </CheckBox>
          )}
          {!isDesktop &&
            proposalStore.pageIndex !== 2 &&
            proposalStore.isLoading !== null && (
              <TempSaveText>
                임시저장 {proposalStore.isLoading ? '중' : '완료'}
              </TempSaveText>
            )}
          <NavBtnWrapper>
            {isDesktop &&
              proposalStore.pageIndex !== 2 &&
              proposalStore.isLoading !== null && (
                <TempSaveText>
                  임시저장 {proposalStore.isLoading ? '중' : '완료'}
                </TempSaveText>
              )}
            {proposalStore.pageIndex > 0 && (
              <PrevBtn
                size={isDesktop ? BtnSize.MEDIUM : BtnSize.SMALL}
                variant={BtnType.SUPER_LOW}
                onClick={prev}
              >
                이전
              </PrevBtn>
            )}
            <NextBtn
              size={isDesktop ? BtnSize.MEDIUM : BtnSize.SMALL}
              onClick={() => {
                // 세번째 페이지(미리보기)일 때는 앞에서 detect하고 넘어왔으므로 바로 propose 실행
                if (isLastPage && !doNotSeeProposalPreview) return propose()
                // "다음"을 눌러 다음 페이지로 넘어가려고 할 때나, "메시지 보내기"를 눌러 미리보기 팝업을 띄우려고 할 때
                // shouldDetect true인 요청이 가장 마지막에 들어가야 하므로, 앞서 debounce를 통해 대기 중인 요청이 있다면 return 함
                if (proposalStore.isLoading) return
                const wrongNames = getWrongNames(
                  proposalStore.fullMessage,
                  proposalStore.request.name,
                )
                if (
                  wrongNames.length > 0 &&
                  !confirm(
                    `메시지에 다른 인재 이름으로 추측되는 문자를 발견했어요!\n발견한 문자: ${wrongNames.join(
                      ', ',
                    )}\n\n그래도 넘어가시겠습니까?`,
                  )
                ) {
                  return
                }
                putRequestDashboardScoutRequestProposal(
                  request.id,
                  !user.isAdmin,
                  proposalStore.temp,
                ).then(({ data }) => {
                  const hasDetected = noticePrivateInfoDetected(
                    data,
                    '스카우트 메시지',
                  )
                  if (hasDetected) return proposalStore.updateTemp()
                  isLastPage && doNotSeeProposalPreview
                    ? proposalStore.setIsConfirmModalOpen(true)
                    : next()
                })
              }}
              disabled={
                proposalStore.isNeedEditTemplate
                  ? true
                  : autoProposalAbleNext
                  ? false
                  : !proposalStore.isAbleNext
              }
              variant={
                proposalStore.isNeedEditTemplate ||
                (!proposalStore.isAbleNext && !autoProposalAbleNext)
                  ? BtnType.DISABLE
                  : BtnType.PRIMARY
              }
            >
              {isLastPage
                ? `메시지 ${!user.isPublished ? '예약' : '보내기'}`
                : '다음'}
            </NextBtn>
          </NavBtnWrapper>
          {!isDesktop &&
            proposalStore.pageIndex === 0 &&
            !autoProposalDisabled && (
              <Button
                variant={
                  autoProposalMessage && autoProposalMessage.isLoading
                    ? BtnType.DISABLE
                    : BtnType.SECONDARY
                }
                size={BtnSize.SMALL}
                onClick={() => {
                  if (autoProposalMessage && autoProposalMessage.isLoading)
                    return
                  autoProposalMessageStore.requestAutoMessage(
                    proposalStore.request.id,
                    proposalStore.request.name,
                  )
                }}
              >
                AI 메시지 자동 생성하기
              </Button>
            )}
        </BottomBar>
        {proposalStore.isConfirmModalOpen && (
          <ProposalMessageConfirmModal
            store={proposalStore}
            onConfirm={() => propose()}
          />
        )}
        {proposalStore.isOpenLockAccount && (
          <LockAccountProcedureModal
            onClose={() => proposalStore.setIsOpenLockAccount(false)}
            hasToBeSigned
          />
        )}
      </>
    )
  },
)

const NavBtnWrapper = styled(Row)`
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  flex: 1;
`

const PrevBtn = styled(Button)`
  width: 100px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
  }
`

const NextBtn = styled(Button)`
  width: 160px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
  }
`

const Section = styled(Row)`
  height: 630px;
  gap: 24px;
  align-items: flex-start;
  overflow: hidden;
  cursor: default;
  padding: 24px;
  @media ${(p) => p.theme.deviceSize.mobile} {
    height: 526px;
    overflow: hidden scroll;
  }
`

const Content = styled(Card)`
  height: 100%;
  justify-content: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    overflow: hidden scroll;
  }
`

const LeftContent = styled(Content)`
  flex: 1;
  padding: 16px;
  border: 1px solid ${(p) => p.theme.color.line};
`

const RightContent = styled(Content)`
  flex: 2;
  border-radius: 0;
`

const BottomBar = styled(BottomStickyBar)`
  z-index: 0;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  cursor: default;
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 8px;
    padding: 16px;
    align-items: center;
    flex-direction: column;
  }
`

const PreviewContainer = styled(Column)`
  gap: 16px;
  height: 630px;
  justify-content: flex-start;
  overflow: hidden;
`

const Header = styled(ShadowCard)`
  gap: 8px;
  text-align: center;
  padding: 24px;
  @media ${(p) => p.theme.deviceSize.desktop} {
    border-radius: 0;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 16px;
    background-color: ${(p) => p.theme.color.background};
  }
`

const MainText = styled(H4Text)`
  font-weight: ${(p) => p.theme.fontWeight.bold};
  color: ${(p) => p.theme.color.primary};
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.h5};
  }
`

const SubText = styled(BaseText)`
  font-weight: ${(p) => p.theme.fontWeight.medium};
  color: ${(p) => p.theme.color.gray2};
  white-space: normal;
  @media ${(p) => p.theme.deviceSize.mobile} {
    white-space: pre-wrap;
    font-size: ${(p) => p.theme.fontSize.small};
  }
`

const PreviewContent = styled(Column)`
  justify-content: start;
  overflow: hidden scroll;
  @media ${(p) => p.theme.deviceSize.desktop} {
    padding: 0 24px;
  }
`

const TempSaveText = styled(SmallText)`
  color: ${(p) => p.theme.color.gray2};
  @media ${(p) => p.theme.deviceSize.desktop} {
    margin-right: 16px;
  }
`
